import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoService } from './../../services/cognito.service';
import { ApiService } from 'src/app/services/api.service';
import { ApiConfig } from 'src/app/services/utilities/configuration/apiConfig';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { HttpErrorResponse } from '@angular/common/http';
import { Keys } from 'src/app/services/utilities/configuration/keys';
import { MetadataTypeEnum } from 'src/app/services/utilities/configuration/enum';
import { PaginationStateService } from 'src/app/services/pagination-state.service';

interface INotification {
  _id: string;
  title: string;
  description: string;
  is_read: boolean;
}

@Component({
  selector: 'app-dashboard-layout',
  styleUrls: ['./dashboard-layout.component.scss'],
  templateUrl: './dashboard-layout.component.html',
})
export class DashboardLayoutComponent {
  public metadataTypeEnum = MetadataTypeEnum;
  metaData: any = [];
  isNotificationPanelVisible: boolean = false;
  unreadCount: number = 0;
  notifications: INotification[] = [];
  notificationUrl: string;
  showViewAll: boolean = false;
  constructor(
    private cognitoService: CognitoService,
    private router: Router,
    private apiService: ApiService,
    private notification: NzNotificationService,
    private paginationStateService: PaginationStateService,
  ) {
    this.getAllMetaData();
    this.notificationUrl = ApiConfig.urlConstant.notification;
  }

  ngOnInit() {
    this.getAllNotification();
    this.getUnreadNotificationCount();
  }

  signOut() {
    this.cognitoService.handleSignOut().then((res: any) => {
      localStorage.clear();
      this.router.navigate(['/login']);
    });
  }

  navigateToHome() {
    this.router.navigate(['/home']);
  }

  getAllNotification() {
    this.apiService
      .getRequest(this.notificationUrl, {
        params: {
          limit: 5,
          sortOrder: 'DESC',
        },
      })
      .subscribe({
        next: (result) => {
          const { data, success } = result;
          if (data) {
            this.notifications = data?.data;
            if (data?.total > 5) this.showViewAll = true;
          }
        },
      });
  }

  markAsRead(id: string) {
    this.apiService.patchRequest(`${this.notificationUrl}/${id}/mark-as-read`).subscribe({
      next: (result) => {
        const { data, success } = result;
        if (data) {
          const indexOfReaded = this.notifications.findIndex((item) => item?._id === id);
          this.notifications[indexOfReaded].is_read = true;
          this.getUnreadNotificationCount();
        }
      },
    });
  }

  markAllAsRead() {
    this.apiService.patchRequest(`${this.notificationUrl}/mark-all-as-read`).subscribe({
      next: (result) => {
        const { data, success } = result;
        if (data) {
          this.notifications.forEach((item) => {
            item.is_read = true;
          });
          this.getUnreadNotificationCount();
        }
      },
    });
  }

  getUnreadNotificationCount() {
    this.apiService.getRequest(`${this.notificationUrl}/unread-count`).subscribe({
      next: (result) => {
        const { data, success } = result;
        if (data?.unreadCount >= 0) {
          this.unreadCount = data?.unreadCount;
        }
      },
    });
  }

  calculateTime(dateString: string) {
    const givenDate = new Date(dateString);
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const differenceInMillis = currentDate.getTime() - givenDate.getTime();

    // Convert the difference to minutes
    const totalMinutes = Math.floor(differenceInMillis / 60000); // 1 minute = 60000 milliseconds

    // Calculate hours and minutes
    const days = Math.floor(totalMinutes / (60 * 24));
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    // Determine what to display
    if (totalMinutes < 60) {
      return `${minutes} min`;
    } else if (totalMinutes < 60 * 24) {
      return `${hours} hr`;
    } else {
      return `${days} day`;
    }
  }

  viewAllNotifications() {}

  getAllMetaData() {
    const urlConst = `?limit=${200}`;

    this.apiService.getRequest(ApiConfig.urlConstant.metaData + urlConst).subscribe({
      next: (result: any) => {
        const { success, data } = result;

        if (data) {
          this.metaData = data;
        } else {
          this.notification.info('Info', 'No record for meta data!');
        }
      },
      error: (err: HttpErrorResponse) => {
        console.error('An error occurred', err);
        this.notification.error('Error', err?.message);
      },
      complete: () => {
        console.log('Completed');
        let dummyMetaData: any = {
          skills: [],
          workCategories: [],
          projectTypes: [],
          softwareSkills: [],
        };

        this.metaData?.forEach((data: any) => {
          switch (data.type) {
            case this.metadataTypeEnum.SKILLS:
              dummyMetaData.skills.push(data);
              break;
            case this.metadataTypeEnum.WORK_CATEGORY:
              dummyMetaData.workCategories.push(data);
              break;
            case this.metadataTypeEnum.PROJECT_TYPE:
              dummyMetaData.projectTypes.push(data);
              break;
            case this.metadataTypeEnum.SOFTWARE_SKILLS:
              dummyMetaData.softwareSkills.push(data);
              break;
          }
        });
        localStorage.setItem(Keys.META_DATA, JSON.stringify(dummyMetaData));
      },
    });
  }

  setPaginationState() {
    this.paginationStateService.saveState(1, 10);
  }
}
