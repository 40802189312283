import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CognitoService } from './cognito.service';
import { Keys } from './utilities/configuration/keys';

@Injectable()
export class AuthGuard {
  constructor(private router: Router, private cognitoService: CognitoService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // TODO: Rewrite this logic according to cognito AWS user session handling
    // const res = await this.cognitoService.currentSession();
    let token = localStorage.getItem(Keys.TOKEN);
    if (token) {
      // Check if token exists && user session also exists
      return true;
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}
