<nz-layout>
  <nz-header class="header">
    <div class="logo">
      <img src="/assets/Logo.svg" alt="Logo Image" />
      <div class="logo-text">Builtdesign</div>
    </div>
    <ul nz-menu nzTheme="light" nzMode="horizontal" class="header-menu">
      <!-- <li nz-menu-item><span nz-icon nzType="setting" nzTheme="outline" class="navbar-icons"></span></li> -->
      <li
        nz-menu-item
        nz-popover
        [nzPopoverContent]="notificationTemplate"
        nzPopoverPlacement="bottomRight"
        [(nzPopoverVisible)]="isNotificationPanelVisible"
      >
        <nz-badge [nzCount]="unreadCount" nzSize="small">
          <span nz-icon nzType="bell" nzTheme="outline" class="navbar-icons"></span>
        </nz-badge>
      </li>
      <ng-template #notificationTemplate>
        <div class="notification-panel">
          <div class="notifcation-header">
            <span>Notifications</span>
            <a nz-button nzType="link" [disabled]="!unreadCount" (click)="markAllAsRead()">Mark all as read</a>
          </div>
          <nz-list [nzDataSource]="notifications" [nzRenderItem]="item">
            <ng-template #item let-notification>
              <nz-list-item
                *ngIf="notification?.action?.type == 'navigate'"
                [class]="notification?.is_read ? ' list-item readed' : 'list-item'"
                [routerLink]="'/project/' + notification?.action?.payload"
                (click)="markAsRead(notification?._id)"
              >
                <div>
                  <nz-list-item-meta [nzTitle]="notification.description + ' (' + notification?.action?.payload + ')'">
                  </nz-list-item-meta>
                </div>
                <span class="time">{{ calculateTime(notification.createdAt) }} ago</span>
              </nz-list-item>
              <nz-list-item
                *ngIf="notification?.action?.type == 'default'"
                [class]="notification?.is_read ? ' list-item readed' : 'list-item'"
                (click)="markAsRead(notification?._id)"
              >
                <div>
                  <nz-list-item-meta [nzTitle]="notification.description"> </nz-list-item-meta>
                </div>
                <span class="time">{{ calculateTime(notification.createdAt) }} ago</span>
              </nz-list-item>
            </ng-template>
          </nz-list>
          <a *ngIf="showViewAll" class="view-all" (click)="viewAllNotifications()">View all notifications</a>
        </div>
      </ng-template>
      <li nz-submenu nzPlacement="bottomRight" [nzTitle]="profileTitle">
        <ul>
          <!-- <li nz-menu-group nzTitle="Item 1">
            <ul>
              <li nz-menu-item>Option 1</li>
              <li nz-menu-item>Option 2</li>
            </ul>
          </li> -->
          <!-- <li nz-menu-group nzTitle="Item 2">
            <ul> -->
          <!-- <li nz-menu-item>Option 3</li>
              <li nz-menu-item>Option 4</li>
              <li nz-submenu nzTitle="Sub Menu">
                <ul>
                  <li nz-menu-item nzDisabled>Option 5</li>
                  <li nz-menu-item>Option 6</li>
                </ul>
              </li> -->
          <li nz-menu-item (click)="signOut()">Sign Out</li>
          <!-- </ul> -->
          <!-- </li> -->
        </ul>
      </li>
      <ng-template #profileTitle class="profile-title-div">
        <nz-avatar
          nzIcon="user"
          nzSrc="//zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
          class="profile-avtar"
        ></nz-avatar>
        <span nz-icon nzType="down" class="profile-submenu-icon"></span>
      </ng-template>
    </ul>
  </nz-header>
  <nz-layout>
    <nz-sider nzWidth="88px" nzTheme="light">
      <ul nz-menu nzMode="inline" class="sider-menu" (nzClick)="setPaginationState()">
        <li
          nz-menu-item
          nzMatchRouter
          class="sidebar-menu-item"
          routerLink="/home"
          nz-tooltip
          nzTooltipTitle="Home"
          nzTooltipPlacement="right"
        >
          <span nz-icon nzType="home" nzTheme="outline" class="sidebar-icons"></span>
        </li>
        <li
          nz-menu-item
          nzMatchRouter
          class="sidebar-menu-item"
          routerLink="/architect"
          nz-tooltip
          nzTooltipTitle="Architects"
          nzTooltipPlacement="right"
        >
          <span nz-icon nzType="user" nzTheme="outline" class="sidebar-icons"></span>
        </li>
        <li
          nz-menu-item
          nzMatchRouter
          class="sidebar-menu-item"
          routerLink="/client"
          nz-tooltip
          nzTooltipTitle="Clients"
          nzTooltipPlacement="right"
        >
          <span nz-icon nzType="user-switch" nzTheme="outline" class="sidebar-icons"></span>
        </li>
        <li
          nz-menu-item
          nzMatchRouter
          class="sidebar-menu-item"
          routerLink="/coa-list"
          nz-tooltip
          nzTooltipTitle="COA"
          nzTooltipPlacement="right"
        >
          <span nz-icon nzType="flag" nzTheme="outline" class="sidebar-icons"> </span>
        </li>
        <li
          nz-menu-item
          nzMatchRouter
          class="sidebar-menu-item"
          routerLink="/dpp"
          nz-tooltip
          nzTooltipTitle="DPP"
          nzTooltipPlacement="right"
        >
          <span nz-icon nzType="trophy" nzTheme="outline" class="sidebar-icons"></span>
        </li>
        <li
          nz-menu-item
          nzMatchRouter
          class="sidebar-menu-item"
          routerLink="/jobs"
          nz-tooltip
          nzTooltipTitle="Jobs (Freelance)"
          nzTooltipPlacement="right"
        >
          <span nz-icon nzType="shopping" nzTheme="outline" class="sidebar-icons"></span>
        </li>

        <li
          nz-menu-item
          nzMatchRouter
          class="sidebar-menu-item"
          routerLink="/chat-supports"
          nz-tooltip
          nzTooltipTitle="Supports"
          nzTooltipPlacement="right"
        >
          <span nz-icon nzType="comment" nzTheme="outline" class="sidebar-icons"></span>
        </li>
        <li
          nz-menu-item
          nzMatchRouter
          class="sidebar-menu-item"
          routerLink="/transactions"
          nz-tooltip
          nzTooltipTitle="Transactions"
          nzTooltipPlacement="right"
        >
          <span nz-icon nzType="wallet" nzTheme="outline" class="sidebar-icons"></span>
        </li>
        <!-- <li
          nz-menu-item
          nzMatchRouter
          class="sidebar-menu-item"
          routerLink="/notifications"
          nz-tooltip
          nzTooltipTitle="Notifications"
          nzTooltipPlacement="right"
        >
          <span nz-icon nzType="notification" nzTheme="outline" class="sidebar-icons"></span>
        </li> -->
        <li
          nz-menu-item
          nzMatchRouter
          class="sidebar-menu-item"
          routerLink="/settings"
          nz-tooltip
          nzTooltipTitle="Settings"
          nzTooltipPlacement="right"
        >
          <span nz-icon nzType="setting" nzTheme="outline" class="sidebar-icons"></span>
        </li>
      </ul>

      <br />
      <br />
      <br />

      <!-- <ul nz-menu nzMode="inline">
        <li nz-menu-item nzMatchRouter class="sidebar-menu-item" routerLink="/settings">
          <span nz-icon nzType="setting" nzTheme="outline" class="sidebar-icons"></span>
        </li>
      </ul> -->
    </nz-sider>

    <nz-layout class="inner-layout">
      <router-outlet></router-outlet>
    </nz-layout>
  </nz-layout>
</nz-layout>
