import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiUrl = `${environment.serverUrl}/users`;

  constructor(private http: HttpClient) {}

  login(data: any): Observable<any> {
    // return this.http.post(`${this.apiUrl}/login`, data, { withCredentials: true });
    return this.http.post(`${this.apiUrl}/login`, data);
  }

  register(data: any): Observable<any> {
    // return this.http.post(`${this.apiUrl}/register`, data, { withCredentials: true });
    return this.http.post(`${this.apiUrl}/register`, data);
  }
}
