import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import { PageNotFoundComponent } from './layouts/404/404.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { DashboardLayoutComponent } from './layouts/dashboard/dashboard-layout.component';
import { TokenService } from './services/token.service';
import { CognitoService } from './services/cognito.service';
import { AuthGuard } from './services/auth-guard.service';
import { HttpAuthInterceptor } from './services/interceptors/http-auth.interceptor';
import { LoaderInterceptor } from './services/interceptors/loader.interceptor';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { SpinnerComponent } from './services/shared/spinner/spinner.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzListModule } from 'ng-zorro-antd/list';
// import { RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
// import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';

registerLocaleData(en);

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, DashboardLayoutComponent, AuthLayoutComponent, SpinnerComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    NzIconModule,
    NzAvatarModule,
    NzDropDownModule,
    NzGridModule,
    NzButtonModule,
    NzNotificationModule,
    NzSpinModule,
    NzToolTipModule,
    NzPopoverModule,
    NzBadgeModule,
    NzListModule,
    // RecaptchaModule,
    // RecaptchaFormsModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    CognitoService,
    TokenService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    // {
    //   provide: RECAPTCHA_SETTINGS,
    //   useValue: {
    //     siteKey: environment.recaptcha.siteKey,
    //   } as RecaptchaSettings,
    // },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
