import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from '../token.service';
import { CognitoService } from '../cognito.service';
import { Keys } from '../utilities/configuration/keys';

@Injectable({
  providedIn: 'root',
})
export class HttpAuthInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenService, private cognitoService: CognitoService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = localStorage.getItem(Keys.TOKEN) || '';
    let authString = 'Bearer ';
    if (token) authString = authString + token;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: authString,
    });
    const cloneRequest = request.clone({
      headers,
    });
    return next.handle(cloneRequest);
  }
}
