export const environment = {
  development: true,
  serverUrl: 'https://api.builtdesign.co.in/api/v1',
  awsConfig: {
    region: 'ap-south-1',
    userBucket: 'user-content.builtdesign.in',
    identityPoolId: 'ap-south-1:8b817c85-7867-4d8c-8599-f59264de17ab',
    userPoolId: 'ap-south-1_6wbrhH2nG',
    userPoolWebClientId: '6s6pgp5n07bbbuispkm6270is4',
    analyticsDisabled: false,
  },
  architectUrl: 'https://architect.builtdesign.co.in/',
  recaptcha: {
    siteKey: '6LdAdy8qAAAAAMK8jq3a7ybx6y1tzG0XZFditz8Q',
    secretKey: '6LdAdy8qAAAAANiZa_ZIqHDlMNiYnLuo5Strp9jk',
  },
  env: 'dev',
  sentryDsn: 'https://7b0a9821fc350bdca71caa630d1dd99e@o4507861625995264.ingest.de.sentry.io/4507883247501393',
};
// https://7b0a9821fc350bdca71caa630d1dd99e@o4507861625995264.ingest.de.sentry.io/4507883247501393
