<nz-layout>
  <div nz-row>
    <div nz-col [nzSpan]="10">
      <nz-layout>
        <nz-content>
          <nz-header>
            <div class="auth-logo">
              <img src="/assets/Logo.svg" alt="Logo Image" />
              <div class="auth-logo-text">Builtdesign</div>
            </div>
          </nz-header>
          <router-outlet></router-outlet>
        </nz-content>
        <nz-footer>
          <div nz-row nzJustify="space-between">
            <div nz-col>&copy; builtdesign</div>
            <div nz-col>
              <span nz-icon nzType="mail" nzTheme="outline"></span>
              help@builtdesign.com
            </div>
          </div>
        </nz-footer>
      </nz-layout>
    </div>
    <div nz-col [nzSpan]="14" class="quote-box">
      <h1>
        Few things make me feel more powerful than setting up automations in Untitled to make my life easier and more
        efficient.
      </h1>
      <div nz-row nzJustify="space-between">
        <div nz-col>
          <div class="author-name">— Aliah Lane</div>
          <span class="author-designation">Founder, Layers.io</span>
        </div>
        <div nz-col>
          <span nz-icon nzType="star" nzTheme="fill" style="font-size: 18px; color: #d91e18"></span>
          <span nz-icon nzType="star" nzTheme="fill" style="font-size: 18px; color: #d91e18"></span>
          <span nz-icon nzType="star" nzTheme="fill" style="font-size: 18px; color: #d91e18"></span>
          <span nz-icon nzType="star" nzTheme="fill" style="font-size: 18px; color: #d91e18"></span>
          <span nz-icon nzType="star" nzTheme="fill" style="font-size: 18px; color: #d91e18"></span>
        </div>
      </div>
    </div>
  </div>
</nz-layout>
