import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PaginationStateService {
  pageIndex = 1;
  pageSize = 10;

  saveState(pageIndex: number, pageSize: number): void {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
  }

  getState(): { pageIndex: number; pageSize: number } {
    return {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
    };
  }
}
