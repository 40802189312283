export class Keys {
  public static readonly TOKEN = 'token';
  public static readonly PROFILE_DATA = 'profileData';
  public static readonly META_DATA = 'metaData';
  public static readonly USER_SUB = 'userSub';
  public static readonly CREATED_SUCCESS = 'Created Successfully';
  public static readonly UPDATED_SUCCESS = 'Updated Successfully';
  public static readonly DELETED_SUCCESS = 'Deleted Successfully';
  public static readonly STATUS_CHANGED = 'Status Changed';
}
