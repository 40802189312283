import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { LoaderService } from '../loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.showLoader();
    return next.handle(req).pipe(finalize(() => this.loaderService.hideLoader()));
    // return next.handle(req).pipe(
    //   tap((evt) => {
    //     if (evt instanceof HttpResponse) {
    //       if (evt != null) {
    //         // here we are hide the loader flag
    //         this.loaderService.hideLoader();
    //       }
    //     }
    //   }),
    //   catchError((err: any) => {
    //     if (err instanceof HttpErrorResponse) {
    //       try {
    //         // if we are getting the erorr we also have to hide the loader
    //         this.loaderService.hideLoader();
    //       } catch (e) {
    //         console.log(e);
    //       }
    //       //log error
    //     }
    //     return throwError(err);
    //   }),
    // );
  }
}
