<div nz-row nzJustify="center" nzAlign="middle">
  <div nz-col [nzSpan]="12">
    <div class="backgroud-lines">
      <div nz-row nzJustify="center">
        <div class="label-404">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
              <circle cx="4" cy="4" r="3" fill="#D91E18" />
            </svg>
          </span>
          <span class="label-404-text"> 404 error </span>
        </div>
      </div>
      <div nz-row nzJustify="center">
        <h1 nz-typography>We can’t find this page</h1>
      </div>
      <div nz-row nzJustify="center">
        <h3 nz-typography>The page you are looking for doesn't exist or has been moved.</h3>
      </div>
      <br />
      <div nz-row nzJustify="center" [nzGutter]="16">
        <div nz-col>
          <button nz-button nzType="default" nzSize="default" nzShape="round" (click)="backClicked()">
            <span nz-icon nzType="arrow-left"></span>
            Go Back
          </button>
        </div>
        <div nz-col>
          <button nz-button nzType="primary" nzSize="default" nzShape="round" [routerLink]="['/home']">Go Home</button>
        </div>
      </div>
    </div>
  </div>
</div>
