import { Injectable } from '@angular/core';
import { Amplify } from 'aws-amplify';
import {
  signIn,
  type SignInInput,
  signUp,
  type SignUpInput,
  getCurrentUser,
  fetchAuthSession,
  type AuthSession,
  confirmSignUp,
  type ConfirmSignUpInput,
  SignUpOutput,
  ConfirmSignUpOutput,
  autoSignIn,
  SignInOutput,
  signOut,
  resendSignUpCode,
  ResendSignUpCodeInput,
  ResendSignUpCodeOutput,
  type GetCurrentUserOutput,
  resetPassword,
  type ResetPasswordOutput,
  type ResetPasswordInput,
  confirmResetPassword,
  type ConfirmResetPasswordInput,
} from 'aws-amplify/auth';
import { CookieStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';
import { Keys } from './utilities/configuration/keys';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: environment.awsConfig.userPoolId,
      userPoolClientId: environment.awsConfig.userPoolWebClientId,
      identityPoolId: environment.awsConfig.identityPoolId,
    },
  },
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());

@Injectable()
export class CognitoService {
  constructor(private userService: UserService, private notification: NzNotificationService) {}

  async handleSignIn({ username, password }: SignInInput) {
    let res!: SignInOutput;
    try {
      res = await signIn({ username, password });
    } catch (error) {
      this.handleError(error);
    }
    return res;
  }

  async signUp(data: any) {
    const signUpInput: SignUpInput = {
      username: data.email,
      password: data.password,
      options: {
        userAttributes: {
          email: data.email, // optional
          phone_number: '+91' + data.phoneNumber,
          given_name: data.name.split(' ')[0],
          family_name: data.name.split(' ').length > 1 ? data.name.split(' ')[1] : data.name.split(' ')[0],
          name: data.name,
          picture: './assets/img/default-avatar.png',
        },
        autoSignIn: true,
      },
    };

    let res!: SignUpOutput;
    try {
      res = await signUp(signUpInput);
    } catch (err) {
      this.handleError(err);
    }

    return res;
  }

  async handleSignUpConfirmation({ username, confirmationCode }: ConfirmSignUpInput) {
    let res!: ConfirmSignUpOutput;
    try {
      // debugger;
      res = await confirmSignUp({
        username,
        confirmationCode,
      });
    } catch (err) {
      this.handleError(err);
    }

    return res;
  }

  async handleAutoSignIn() {
    let res!: SignInOutput;
    try {
      res = await autoSignIn();
      // handle sign-in steps
    } catch (err) {
      this.handleError(err);
    }

    return res;
  }

  async handleResendSignUpCode({ username }: ResendSignUpCodeInput) {
    let res!: ResendSignUpCodeOutput;
    try {
      res = await resendSignUpCode({ username });
    } catch (err) {
      this.handleError(err);
    }

    return res;
  }

  async handleSignOut() {
    let res!: any;
    try {
      res = await signOut();
    } catch (err) {
      this.handleError(err);
    }

    return res;
  }

  async handleResetPassword({ username }: ResetPasswordInput) {
    let res!: ResetPasswordOutput;
    try {
      res = await resetPassword({ username });
    } catch (err) {
      this.handleError(err);
    }

    return res;
  }

  async handleConfirmResetPassword({ username, confirmationCode, newPassword }: ConfirmResetPasswordInput) {
    let res!: any;
    try {
      res = await confirmResetPassword({ username, confirmationCode, newPassword });
    } catch (err) {
      this.handleError(err);
    }

    return res;
  }

  // changePassword (data) {
  //     return new Promise((res, rej) => {
  //         Auth.currentAuthenticatedUser()
  //             .then(user => {
  //                 return Auth.changePassword(user, data.oldpassword, data.newpassword);
  //             })
  //             .then(data => {
  //                 // console.log(data);
  //                 this.notificationService.showNotification('success', "Password successfully changed.");
  //                 res(data);
  //             })
  //             .catch(err => {
  //                 this.handleError(err);
  //                 rej(err);
  //             });
  //     });
  // }

  // newPasswordChallenge (user: any, data: any) {
  //     if (user.challengeParam.requiredAttributes.name == "") {
  //         user.challengeParam.requiredAttributes.name = `${user.challengeParam.requiredAttributes.first_name} ${user.challengeParam.requiredAttributes.last_name}`
  //     }
  //     return new Promise((res, rej) => {
  //         const that = this;
  //         user.completeNewPasswordChallenge(data.newpassword, user.challengeParam.requiredAttributes, {
  //             onSuccess: function (session) {
  //                 Auth.currentAuthenticatedUser().then((user) => {
  //                     // console.log(user);
  //                     res(user);
  //                 });
  //             },
  //             onFailure: function (err) {
  //                 that.handleError(err);
  //                 // console.log('completeNewPassword failure', err);
  //                 rej(err);
  //             }
  //         });
  //     });
  // }

  // forgotPassword (data) {
  //     return new Promise((res, rej) => {
  //         Auth.forgotPassword(data.email)
  //             .then(data => {
  //                 // console.log(data);
  //                 this.notificationService.showNotification('info', `Code sent to ${data.CodeDeliveryDetails.Destination} successfully.`);
  //                 res(data);
  //             })
  //             .catch(err => {
  //                 this.handleError(err);
  //                 rej(err);
  //             });
  //     });
  // }

  // forgotCodeSubmit (username, code, new_password) {
  //     // Collect confirmation code and new password, then
  //     return new Promise((res, rej) => {
  //         Auth.forgotPasswordSubmit(username, code, new_password)
  //             .then((data: any) => {
  //                 // console.log(data);
  //                 this.notificationService.showNotification('success', "Password successfully changed.");
  //                 res(data);
  //             })
  //             .catch(err => {
  //                 this.handleError(err);
  //                 rej(err);
  //             });
  //     });
  // }

  async currentAuthenticatedUser() {
    let res!: GetCurrentUserOutput;
    try {
      res = await getCurrentUser();
      // console.log(`The username: ${res.username}`);
      // console.log(`The userId: ${res.userId}`);
      // console.log(`The signInDetails: ${res.signInDetails}`);
    } catch (err) {
      this.handleError(err);
    }
    console.log(res);
    return res;
  }

  async currentSession() {
    let res!: AuthSession;
    try {
      res = await fetchAuthSession();
      // console.log('Current Session : ', res);
      localStorage.setItem(Keys.USER_SUB, res.userSub ? res.userSub : '');
    } catch (err) {
      this.handleError(err);
    }

    return res;
  }

  private handleError(error: any) {
    if (error?.name == 'UserAlreadyAuthenticatedException') {
      this.handleSignOut();
    }
    this.notification.create('error', 'Error!', error.message || error);
    console.error(error);
  }
}
